<template>
  <div class="container">
    <div class="card mt-4" style="max-width: 800px; margin: 0 auto !important">
      <div class="card-body">
        <Filtros :title="`Faturamento`" @filtrar="filtrar" :initial="filtros">
          <div class="row pb-2">
            <div class="col-12 col-sm-4 text-left pr-0">
              <label style="margin-bottom:7px"
                >Tipo de Lançamento / Venda</label
              >
              <v-select
                multiple
                v-model="filtros.tipo_venda"
                :options="[
                  'Consulta',
                  'Exame',
                  'Fidelização',
                  'Produto',
                  'Acerto',
                ]"
              />
            </div>
            <div class="col-12 col-sm-4 text-left pl-2 pr-0"></div>
          </div>
        </Filtros>
      </div>
    </div>

    <div
      class="alert alert-danger text-center"
      v-if="
        exibir &&
          filtros &&
          (!filtros.periodo || !filtros.periodo.dt_i || !filtros.periodo.dt_f)
      "
    >
      <h1>
        <b-icon-exclamation-diamond />
      </h1>
      <br />
      Seleciona ao menis periodo para iniciar a busca.
    </div>

    <div class="text-center p-5" v-if="loading">
      <b-spinner /> <br />
      aguarde...
    </div>
    <div class="row" v-if="!loading">
      <hr />
      <div class="text-right">
        <b-btn variant="none" @click="imp"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <div class="col-12" v-if="dados && dados.meses" id="printDIV">
        <print-component
          :printNow="true"
          :show="true"
          :titulo="`Relatório de Faturamento`"
          :filtros="filtroTexto"
          :orientationPDF="'landscape'"
        >
          <div class="text-center">
            <small>
              <b>
                *Os valores são exibidos na sequência Quantidade / Valor de
                Venda / Valor de Custo / Lucro
              </b>
            </small>
          </div>
          <b-table-simple small style="font-size:10px" striped bordered>
            <b-thead>
              <b-tr>
                <b-th> </b-th>
                <b-th>
                  Total Geral
                </b-th>
                <b-th v-for="mes in dados.meses" :key="`periodo${mes}_`">
                  {{ mes }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="tipo in dados.tipos">
                <b-tr :key="`tipo${tipo.tipo}`" class="text-left">
                  <b-th class="pt-4 pl-2">
                    <h5>
                      <b>{{ tipo.tipo }}</b>
                    </h5>
                  </b-th>
                  <b-th class="text-right">
                    {{ somar(tipo.tipo, null, null, "qtd") }}<br />
                    {{ somar(tipo.tipo, null, null, "vl_venda") | currency }}
                    <br />
                    {{ somar(tipo.tipo, null, null, "vl_custo") | currency
                    }}<br />
                    <b>
                      {{
                        (somar(tipo.tipo, null, null, "vl_venda") -
                          somar(tipo.tipo, null, null, "vl_custo"))
                          | currency
                      }}</b
                    >
                  </b-th>
                  <b-td
                    class="text-right"
                    v-for="mes in dados.meses"
                    :key="`tipoPorPeriodo${tipo.tipo}${mes}_`"
                  >
                    {{ somar(tipo.tipo, null, mes, "qtd") }}<br />
                    {{ somar(tipo.tipo, null, mes, "vl_venda") | currency }}
                    <br />
                    {{ somar(tipo.tipo, null, mes, "vl_custo") | currency
                    }}<br />
                    <b>
                      {{
                        (somar(tipo.tipo, null, mes, "vl_venda") -
                          somar(tipo.tipo, null, mes, "vl_custo"))
                          | currency
                      }}</b
                    >
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="especialidade in tipo.especialidades"
                  :key="`tipo${tipo.tipo}_${especialidade.especialidade}`"
                >
                  <td class="pl-5 text-left pt-4">
                    <h6>{{ especialidade.especialidade }}</h6>
                  </td>
                  <b-th class="text-right">
                    {{
                      somar(tipo.tipo, especialidade.especialidade, mes, "qtd")
                    }}<br />
                    {{
                      somar(
                        tipo.tipo,
                        especialidade.especialidade,
                        null,
                        "vl_venda"
                      ) | currency
                    }}
                    <br />
                    {{
                      somar(
                        tipo.tipo,
                        especialidade.especialidade,
                        null,
                        "vl_custo"
                      ) | currency
                    }}<br />
                    <b
                      >{{
                        (somar(
                          tipo.tipo,
                          especialidade.especialidade,
                          null,
                          "vl_venda"
                        ) -
                          somar(
                            tipo.tipo,
                            especialidade.especialidade,
                            null,
                            "vl_custo"
                          ))
                          | currency
                      }}
                    </b>
                  </b-th>
                  <b-td
                    class="text-right"
                    v-for="mes in dados.meses"
                    :key="
                      `tipo${tipo.tipo}_${especialidade.especialidade}_${tipo.tipo}${mes}_`
                    "
                  >
                    {{
                      somar(tipo.tipo, especialidade.especialidade, mes, "qtd")
                    }}<br />
                    {{
                      somar(
                        tipo.tipo,
                        especialidade.especialidade,
                        mes,
                        "vl_venda"
                      ) | currency
                    }}
                    <br />
                    {{
                      somar(
                        tipo.tipo,
                        especialidade.especialidade,
                        mes,
                        "vl_custo"
                      ) | currency
                    }}<br />
                    <b
                      >{{
                        (somar(
                          tipo.tipo,
                          especialidade.especialidade,
                          mes,
                          "vl_venda"
                        ) -
                          somar(
                            tipo.tipo,
                            especialidade.especialidade,
                            mes,
                            "vl_custo"
                          ))
                          | currency
                      }}
                    </b>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>

            <b-tr>
              <b-th class="pt-4">
                <h3>Total Geral</h3>
              </b-th>
              <b-th class="text-right font-weight-bold">
                {{ somar(null, null, null, "qtd") }}<br />
                {{ somar(null, null, null, "vl_venda") | currency }}
                <br />
                {{ somar(null, null, null, "vl_custo") | currency }}<br />
                <b
                  >{{
                    (somar(null, null, null, "vl_venda") -
                      somar(null, null, null, "vl_custo"))
                      | currency
                  }}
                </b>
              </b-th>
              <b-th
                class="text-right"
                v-for="mes in dados.meses"
                :key="`tipoPorPeriodoTotalGHeral${mes}_`"
              >
                {{ somar(null, null, mes, "qtd") }}<br />
                {{ somar(null, null, mes, "vl_venda") | currency }}
                <br />
                {{ somar(null, null, mes, "vl_custo") | currency }}<br />
                <b>
                  {{
                    (somar(null, null, mes, "vl_venda") -
                      somar(null, null, mes, "vl_custo"))
                      | currency
                  }}</b
                >
              </b-th>
            </b-tr>
          </b-table-simple>
        </print-component>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PrintComponent from "../../components/Print/PrintComponent.vue";
import Filtros from "../../components/Relatorios/Filtros.vue";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";
import RelatoriosLib from "../../libs/RelatoriosLib";

export default {
  components: {
    Filtros,
    PrintComponent,
  },
  props: {},
  mounted() {},
  data() {
    return {
      loading: false,
      totalizador: {
        pagamentos: [],
        resumoPorTipo: [],
        qtd: 0,
        vl_total: 0,
        vl_custo: 0,
      },
      exibir: false,
      filtros: {},
      print: false,
      lista: null,
      dados: null,
      filtroTexto: "",
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.exibir = false;
        this.print = false;
      },
    },
  },
  computed: {},
  methods: {
    imp() {
      //this.show = false;
      //this.exibir = false;
      PrinterWindowHelper(
        document.getElementById("printDIV").innerHTML,
        5,
        true
      );
      this.$nextTick();
      setTimeout(() => {
        this.exibir = true;
      }, 300);
    },
    somar(tipo, especialidade, periodo, campo) {
      // console.log("somar", tipo, especialidade, periodo, campo);
      return parseFloat(
        this.lista
          .filter(
            (vl) =>
              (!tipo || (tipo && vl.tipo == tipo)) &&
              (!especialidade ||
                (especialidade && vl.especialidade == especialidade)) &&
              (!periodo || (periodo && vl.periodo == periodo))
          )
          .reduce((ret, vl) => {
            ret += vl[campo];

            return ret;
          }, 0)
      );
    },
    async filtrar(filtros) {
      this.loading = true;
      console.log(filtros);
      this.filtroTexto = `Periodo ${moment(filtros.periodo.dt_i).format(
        "DD/MM/YYYY"
      )} até ${moment(filtros.periodo.dt_f).format("DD/MM/YYYY")}`;
      this.show = true;
      this.exibir = false;
      this.filtros = filtros;
      this.$nextTick();

      try {
        let res = await RelatoriosLib.getRelatorioFaturamento(this.filtros);
        console.log(res);
        if (res && res.success) {
          this.lista = res.data;
          if (this.lista.length > 0) {
            this.dados = {
              meses: this.lista.reduce((ret, vl) => {
                if (!ret.some((x) => x == vl.periodo)) {
                  ret.push(vl.periodo);
                }
                return ret;
              }, []),
              tipos: this.lista.reduce((ret, vl) => {
                if (!ret.some((x) => x.tipo == vl.tipo)) {
                  ret.push({
                    tipo: vl.tipo,
                    especialidades: this.lista
                      .filter((x) => x.tipo == vl.tipo)
                      .reduce((r2, v2) => {
                        if (
                          !r2.some((x) => x.especialidade == v2.especialidade)
                        ) {
                          r2.push({
                            especialidade: v2.especialidade,
                          });
                        }
                        return r2;
                      }, []),
                  });
                }
                return ret;
              }, []),
            };
          }
        }
      } catch (error) {
        console.log("Error on get relat fat from api", error, this.filtros);
      }

      setTimeout(() => {
        this.exibir = true;
      }, 300);

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
